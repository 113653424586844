import React, { Component } from 'react'
import { Pulse } from 'styled-spinkit'
import JSCookie from 'js-cookie'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import siteConfig from '../../util/site-config'

import {
  theme,
  FormWrap,
  FormRow,
  FormColFull,
  FormCol,
  FormTextInput,
  FormItem,
  FormTitle,
  FormSubmit,
  FormButton,
  PulseWrap,
  FormTextarea,
} from '../../util/style'
import * as yup from 'yup'
import Select from 'react-select'
import _ from 'lodash'
import styled from 'styled-components'

const mapDispatchToProps = (dispatch) => ({})

const intentionOptions = [
  {
    id: 0,
    label: 'Which are you?',
    value: '',
  },
  {
    id: 1,
    label: 'Considering a custom haus',
    value: 'custom',
  },
  {
    id: 2,
    label: 'Considering knockdown rebuild',
    value: 'knockdown',
  },

  {
    id: 4,
    label: 'Learning about Happy Haus',
    value: 'learning',
  },
  {
    id: 5,
    label: 'Looking to build a new haus',
    value: 'new_haus',
  },
]

const INITIAL_STATE = {
  intention: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  addressPostcode: '',
  details: '',
  errors: {},
  isSending: false,
  isSent: false,
}

class Form extends Component {
  constructor(props) {
    super(props)

    const { category } = this.props

    this.state = INITIAL_STATE
    this._onSubmit = this._onSubmit.bind(this)
    this._onFieldChange = this._onFieldChange.bind(this)
    this._onSelectChange = this._onSelectChange.bind(this)
    this._onRadioChange = this._onRadioChange.bind(this)
  }

  componentDidUpdate() {
    if (this.state.isSent) {
      setTimeout(() => {
        this.setState(INITIAL_STATE)
      }, 4000)
    }
  }

  _onSubmit() {
    const self = this
    const {
      intention,
      firstName,
      lastName,
      email,
      phone,
      addressPostcode,
      details,
    } = this.state

    const { category, redirect, dispatch } = this.props

    this.setState({
      isSending: true,
    })

    const validationOptions = {
      abortEarly: false,
    }

    const schema = yup.object().shape({
      intention: yup.string().required('Message...'),
      hs_persona: yup.string().required('Message...'),
      firstName: yup.string().required('Message...'),
      lastName: yup.string().required('Message...'),
      email: yup.string().required('Message...').email('Must be a valid email'),
      phone: yup.string().required('Message...'),
      addressPostcode: yup.string().required('Message...'),
    })

    const date = new Date()
    const timestamp = date.getTime()

    let pageUri = 'https://happyhaus.com.au'
    let pageTitle = 'Happy Haus'
    let pageCookie = ''
    if (typeof window !== `undefined`) {
      pageUri = window.location.href
      pageTitle = document.title
      pageCookie = JSCookie.get('hubspotutk')
    }

    const context = {
      hutk: pageCookie,
      pageUri: pageUri,
      pageName: pageTitle,
    }

    const hs_persona = siteConfig.personas[intention]
    const intentionChosen = _.find(intentionOptions, { value: intention }).label

    const data = {
      submittedAt: timestamp,
      context: context,
      intentionChosen: intentionChosen,
      fields: {
        intention: intention,
        hs_persona: hs_persona,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        addressPostcode: addressPostcode,
        conversionPage: pageTitle,
      },
    }

    schema
      .validate(data.fields, validationOptions)
      .then(function (value) {
        console.log('VALID')

        fetch('/.netlify/functions/registration-hubspot', {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then(function (response) {
            if (response.status === 200) {
              self.setState({
                isSending: false,
                isSent: true,
              })

              console.log('SUBMITTED ✔')
            }
          })
          .catch(function (err) {
            console.log('ERROR ✔', err)
            self.setState({ ...self.state, isSending: false })
          })
      })
      .catch(function (err) {
        const newErrors = {}
        _.forEach(err.inner, function (err) {
          newErrors[err.path] = err.message
        })
        self.setState({ ...self.state, errors: newErrors, isSending: false })
      })
  }

  _onRadioChange(key, val) {
    this.setState({
      [key]: val,
    })
  }

  _onFieldChange(e) {
    const target = e.target
    const key = target.name

    const val = target.type === 'checkbox' ? target.checked : target.value
    this.setState({
      [key]: val,
    })
  }

  _onSelectChange(selected, el) {
    const key = el.name
    const val = selected.value
    this.setState({
      [key]: val,
    })
  }

  _isChecked(key) {}

  _isError(field) {
    return this.state.errors[field]
  }

  render() {
    const { intention, isSending, isSent } = this.state
    const intentionDefault =
      _.find(intentionOptions, { value: intention }) || intentionOptions[0]

    let submitButton
    if (isSending) {
      submitButton = (
        <PulseWrap>
          <Pulse size="32" color={theme.colors.bronze} />
        </PulseWrap>
      )
    } else {
      submitButton = <FormButton onClick={this._onSubmit}>Send</FormButton>
    }

    return (
      <FormWrap>
        <FormRow isSending={isSending}>
          <FormColFull>
            <p style={{ textAlign: 'center' }}>
              All visitors are required to register the details
              <br />
              of one person per group, thank you.
            </p>
          </FormColFull>
        </FormRow>

        <input type="hidden" value="something" />

        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this._isError('intention')}>
              <Select
                value={intentionDefault}
                name="intention"
                onChange={this._onSelectChange}
                options={intentionOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                defaultMenuIsOpen={false}
              />
            </FormItem>
          </FormColFull>
        </FormRow>
        <FormRow isSending={isSending}>
          <FormColFull>
            <FormItem isError={this._isError('email')}>
              <FormTextInput
                type="text"
                name="email"
                placeholder="Email Address"
                value={this.state.email}
                onChange={this._onFieldChange}
                autocomplete="off"
              />
            </FormItem>
          </FormColFull>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormCol>
            <FormItem isError={this._isError('firstName')}>
              <FormTextInput
                type="text"
                name="firstName"
                placeholder="First Name"
                value={this.state.firstName}
                onChange={this._onFieldChange}
                autocomplete="off"
              />
            </FormItem>
          </FormCol>
          <FormCol>
            <FormItem isError={this._isError('lastName')}>
              <FormTextInput
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={this.state.lastName}
                onChange={this._onFieldChange}
                autocomplete="off"
              />
            </FormItem>
          </FormCol>
        </FormRow>

        <FormRow isSending={isSending}>
          <FormCol>
            <FormItem isError={this._isError('phone')}>
              <FormTextInput
                type="text"
                name="phone"
                placeholder="Phone"
                value={this.state.phone}
                onChange={this._onFieldChange}
                autocomplete="off"
              />
            </FormItem>
          </FormCol>
          <FormCol>
            <FormItem isError={this._isError('addressPostcode')}>
              <FormTextInput
                type="text"
                name="addressPostcode"
                placeholder="Postcode"
                value={this.state.addressPostcode}
                onChange={this._onFieldChange}
                autocomplete="off"
              />
            </FormItem>
          </FormCol>
        </FormRow>

        <FormSubmit>{submitButton}</FormSubmit>
        <Message isSent={isSent}>
          <div>
            <FormTitle>
              Thank you for visiting
              <br />
              our Display Haus, Enjoy!
              {/* <PulseWrap>
                <Pulse size="32" color={theme.colors.bronze} />
              </PulseWrap> */}
            </FormTitle>
          </div>
        </Message>
      </FormWrap>
    )
  }
}

export default connect(mapDispatchToProps)(Form)

const Message = styled.div`
  position: absolute;
  top: ${(props) => (props.isSent ? `0px` : `-9999px`)};
  left: ${(props) => (props.isSent ? `0px` : `-9999px`)};
  bottom: ${(props) => (props.isSent ? `0px` : `auto`)};
  right: ${(props) => (props.isSent ? `0px` : `auto`)};
  background: ${theme.colors.white};
  display: block;
  transition: opacity 0.2s ease-out;
  opacity: ${(props) => (props.isSent ? `1` : `0`)};
  display: flex;
  justify-content: center;
  align-items: center;
`
